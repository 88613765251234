import { gql } from '@apollo/client';

export const chaseSupportQuery = gql`
  query {
    dashboard {
      ticketTypes
      features {
        featureId
        featureName
        featureStatus
      }
      lobApplicationId
      lobName
      applicationName
      groups {
        groupId
        groupName
        description
        createdAt
        lastUpdated
        groupType
        createdBy {
          userId
          firstName
          lastName
        }
        groupRules {
          ruleId
          groupId
          lobApplicationId
          partnerId
          accountId
          solutionId
          solutionEnvironmentId
          customersImpacted
          ticketSeverity
          ticketType
          isDefaultRule
          createdAt
          lastUpdated
          createdBy {
            userId
            firstName
            lastName
          }
        }
        members {
          userId
          firstName
          lastName
          roleName
          createdAt
          createdBy {
            userId
            firstName
            lastName
          }
        }
      }
      partners {
        partnerId
        name
        features {
          featureId
          featureName
          featureStatus
        }
        accounts {
          accountId
          name
          lobApplicationId
          solutions {
            solutionId
            name
            solutionEnvironments {
              solutionEnvironmentId
              environmentId
              name
            }
          }
        }
      }
    }
  }
`;
