import { lazy } from 'react';
import { LayoutComponent } from './components';
import { UserAccessInterface } from './utils/types/dashboardTypes';

import { GlobalStoreContext, ScrollToTop, useWindowSize } from './utils';
import {
  getUserProfile,
  generatePlaygroundToken,
} from './utils/isUserLoggedIn';
import {
  LoggedOutPage,
  AccessDeniedErrorPage,
} from './components/pages/SessionAndErrorPages';
import { updatePartnerAdminLastLogin } from './services/webClientAxiosCalls';
import UserProfileInterface, {
  UserPersonaEnum,
} from './utils/types/dashboardTypes';
import UserProfileAccessInfoInterface from './utils/types/dashboardTypes';

import { deriveUserPersona, isUserPartnerCentral } from './utils/personaUtils';
import {
  personaMapsInterface,
  ticketMapsInterface,
} from './utils/hooks/GlobalStoreProvider';

import slugify from './utils/slugify';
import solutions from './components/pages/ExternalDeveloperPortal/Solutions/solutionsData.json';
import { setLoginTime } from './utils/time/loginTimeUtils';
const AdminPage = lazy(
  () => import('./components/pages/LeftSideNav/AdminPage/AdminPage'),
);

const ContactUs = lazy(
  () =>
    import('./components/pages/ExternalDeveloperPortal/ContactUs/ContactUs'),
);
const Solution = lazy(
  () => import('./components/pages/ExternalDeveloperPortal/Solutions/Solution'),
);
const FAQ = lazy(
  () => import('./components/pages/ExternalDeveloperPortal/FAQ/FAQ'),
);
const LSNSolutions = lazy(
  () => import('./components/pages/LeftSideNav/Solutions/LSNSolutions'),
);
const SessionAndErrorPagesLayoutComponent = lazy(
  () =>
    import(
      './components/pages/SessionAndErrorPages/SessionAndErrorPagesLayoutComponent'
    ),
);
const Homepage = lazy(
  () => import('./components/pages/ExternalDeveloperPortal/Homepage/Homepage'),
);
const SingleTicketPage = lazy(
  () => import('./components/pages/Tickets/SingleTicketPage/SingleTicketPage'),
);
const Terms = lazy(
  () => import('./components/pages/ExternalDeveloperPortal/Terms/Terms'),
);
const DashboardLayout = lazy(
  () =>
    import(
      './components/global-components/Layouts/AuthenticatedLayout/AuthenticatedLayout'
    ),
);
const ApiDocumentation = lazy(
  () => import('./components/pages/ApiDocumentation/ApiDocumentation'),
);
const InactivityModal = lazy(
  () =>
    import(
      './components/global-components/Layouts/AuthenticatedLayout/components/InactivityModal/InactivityModal'
    ),
);
const Catalog = lazy(
  () =>
    import(
      './components/pages/ExternalDeveloperPortal/CatalogPortal/ProductCatalog/Catalog'
    ),
);
const OurProcess = lazy(
  () =>
    import(
      './components/pages/ExternalDeveloperPortal/Solutions/OurProcess/OurProcess'
    ),
);
const ExternalLayout = lazy(
  () =>
    import(
      './components/global-components/Layouts/AnonymousLayout/AnonymousLayout'
    ),
);

const ViewSingleAssigneeGroupPage = lazy(
  () =>
    import(
      './components/pages/LeftSideNav/AdminPage/AdminPageTabs/ManageLOBAssignmentTab/ViewSingleAssigneeGroupPage/ViewSingleAssigneeGroupPage'
    ),
);
// import ProductsRouter from './components/pages/ExternalDeveloperPortal/CatalogPortal/ProductCatalog/ProductsRouter';
// const slugify= lazy(
//   () =>
//     import(
//       './components/pages/BreakoutPages/components/SinglePartnerBreakoutPage/SinglePartnerBreakoutPage'
//     )
// );
const ProductsRouter = lazy(
  () =>
    import(
      './components/pages/ExternalDeveloperPortal/CatalogPortal/ProductCatalog/ProductsRouter'
    ),
);
const SinglePartnerBreakoutPage = lazy(
  () =>
    import(
      './components/pages/BreakoutPages/components/SinglePartnerBreakoutPage/SinglePartnerBreakoutPage'
    ),
);
const SingleAccountBreakoutPage = lazy(
  () =>
    import(
      './components/pages/BreakoutPages/components/SingleAccountBreakoutPage/SingleAccountBreakoutPage'
    ),
);
const SingleSolutionBreakoutPage = lazy(
  () =>
    import(
      './components/pages/BreakoutPages/components/SingleSolutionBreakoutPage/SingleSolutionBreakoutPage'
    ),
);
const Glossary = lazy(
  () => import('./components/pages/ExternalDeveloperPortal/Glossary/Glossary'),
);
const GeneralLayout = lazy(
  () => import('./components/global-components/Layouts/GeneralLayout'),
);
const SingleEnvironmentBreakoutPage = lazy(
  () =>
    import(
      './components/pages/BreakoutPages/components/SingleEnvironmentBreakoutPage/SingleEnvironmentBreakoutPage'
    ),
);
const MemberLSNPage = lazy(
  () => import('./components/pages/LeftSideNav/Members/MemberLSNPage'),
);
const LSNAccounts = lazy(
  () => import('./components/pages/LeftSideNav/Accounts/LSNAccounts'),
);
const ViewAllTicketsPage = lazy(
  () =>
    import('./components/pages/Tickets/ViewAllTicketsPage/ViewAllTicketsPage'),
);
const DashboardTwoPointZero = lazy(
  () => import('./components/pages/Dashboard/Dashboard'),
);

export {
  DashboardLayout,
  GeneralLayout,
  DashboardTwoPointZero,
  LayoutComponent,
  getUserProfile,
  AccessDeniedErrorPage,
  InactivityModal,
  updatePartnerAdminLastLogin,
  GlobalStoreContext,
  ScrollToTop,
  useWindowSize,
  SessionAndErrorPagesLayoutComponent,
  LoggedOutPage,
  Homepage,
  deriveUserPersona,
  Catalog,
  OurProcess,
  ExternalLayout,
  Solution,
  solutions,
  Terms,
  FAQ,
  ContactUs,
  slugify,
  ProductsRouter,
  SinglePartnerBreakoutPage,
  SingleAccountBreakoutPage,
  SingleSolutionBreakoutPage,
  AdminPage,
  SingleEnvironmentBreakoutPage,
  UserPersonaEnum,
  isUserPartnerCentral,
  Glossary,
  LSNSolutions,
  LSNAccounts,
  ApiDocumentation,
  generatePlaygroundToken,
  MemberLSNPage,
  ViewAllTicketsPage,
  SingleTicketPage,
  ViewSingleAssigneeGroupPage,
  setLoginTime,
};

export type {
  UserProfileInterface,
  UserAccessInterface,
  UserProfileAccessInfoInterface,
  personaMapsInterface,
  ticketMapsInterface,
};
