import { gql } from '@apollo/client';

export const partnerAdminQuery = gql`
  query {
    dashboard {
      lobApplicationId
      lobName
      applicationName
      ticketTypes
      features {
        featureId
        featureName
        featureStatus
      }
      roles {
        roleId
        name
        description
        roleType
      }
      environments {
        environmentId
        name
        status
        createdAt
        lastUpdated
      }
      accounts {
        accountId
        partnerId
        lobApplicationId
        description
        name
        partnerName
        shortName
        status
        createdAt
        lastUpdated
        solutions {
          solutionId
          accountId
          name
          shortName
          description
          status
          createdAt
          lastUpdated
          memberRole
          members {
            userId
            firstName
            lastName
            emailAddress
            status
            userType
            lastLogin
            lastUpdated
            createdAt
            roleName
            environments
            dateAdded
          }
          solutionEnvironments {
            solutionEnvironmentId
            environmentId
            name
            status
            createdAt
            lastUpdated
            apis {
              apiId
              name
              status
              publishedAt
              version
            }
          }
        }
      }
    }
  }
`;
