import { IRole, IFeatureAction } from './';

export const enum UserPersonaEnum {
  ChaseSuperAdmin,
  ChaseAdmin,
  ChaseSupportTeam,
  RelationshipManager,
  PartnerAdmin,
  PartnerRelationshipManager,
  PartnerDeveloper,
  ExternalDeveloper,
  AnonymousUser,
}

export enum RoleEnum {
  ChaseSuperAdmin = 'ChaseSuperAdmin',
  ChaseAdmin = 'ChaseAdmin',
  ChaseSupportTeam = 'ChaseSupportTeam',
  RelationshipManager = 'RelationshipManager',
  PartnerAdmin = 'PartnerAdmin',
  PartnerRelationshipManager = 'PartnerRelationshipManager',
  PartnerDeveloper = 'PartnerDeveloper',
  ExternalDeveloper = 'ExternalDeveloper',
  AnonymousUser = 'AnonymousUser',
}

export interface UserAccessInterface {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userId: string;
  role: IRole;
  actions: IFeatureAction[];
}
export default interface UserProfileInterface {
  isLoggedIn: boolean;
  isPartnerCentralUser: boolean;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userId: string;
  userPersona:
    | UserPersonaEnum.ChaseAdmin
    | UserPersonaEnum.RelationshipManager
    | UserPersonaEnum.ChaseSuperAdmin
    | UserPersonaEnum.ChaseSupportTeam
    | UserPersonaEnum.PartnerAdmin
    | UserPersonaEnum.PartnerRelationshipManager
    | UserPersonaEnum.PartnerDeveloper
    | UserPersonaEnum.ExternalDeveloper
    | UserPersonaEnum.AnonymousUser;
  isExternalUser: boolean;
  isChaseUser: boolean;
}

export interface TicketIdParams {
  ticketId: string;
}

export interface PartnerIdParams {
  partnerId: string;
}

export interface AccountIdParams {
  partnerId: string;
  accountId: string;
}

export interface SolutionIdParams {
  partnerId: string;
  accountId: string;
  solutionId: string;
}

export interface GroupIdParams {
  groupId: string;
}

export interface EnvironmentIdParams {
  partnerId: string;
  accountId: string;
  solutionId: string;
  solutionEnvironmentId: string;
}

export interface ApiIdParams {
  partnerId: string;
  accountId: string;
  solutionId: string;
  solutionEnvironmentId: string;
  apiId: string;
}

export interface StatusType {
  current_status: string;
  target_status: string;
}

/* 
  API typings 
*/

export interface SingleSolutionType {
  solution_id: string;
  account_id: string;
  name: string;
  short_name: string;
  description: string;
  max_user_count?: number;
  status: string;
  user_count: number;
  api_count: number;
  created_at: string;
  last_updated: string;
}

export interface PageInfoType {
  totalPages: number;
  totalItems: number;
  currentPage: number;
  pageSize: number;
}

export interface PartnerAccountType {
  account_id: string;
  created_at: string;
  description: string;
  last_updated: string;
  name: string;
  partner_id: string;
  short_name: string;
  solution_id: string;
  solution_name: string;
  status: string;
}

export interface TransformedPartnerAdminDataType extends PartnerAccountType {
  solutions: [];
}

export interface SinglePartnerType {
  partner_id: string;
  name: string;
  short_name: string;
  description: string;
  status: string;
  accounts: SingleAccountType[];
}

export interface EnvironmentApiType {
  solution_environment_id: string;
  solution_id: string;
  environment_id: string;
  api_name: string;
  api_status: string;
  api_id: string;
  status: string;
  environment_name: string;
  environment_status: string;
  created_at: string;
  last_updated: string;
}

export interface EnvironmentType {
  environment_id: string;
  name: string;
  short_name: string;
}

export interface SingleAccountType {
  account_id: string;
  name: string;
  description: string;
  short_name: string;
  solution_count: number;
  user_count: number;
  solutions: Record<string, SingleSolutionType>;
  createdDate?: string;
  updatedDate?: string;
  partner_id?: string;
  id?: string;
  status: string;
}

export interface MemberType {
  created_at: string;
  email_address: string;
  environment_name: string;
  environment_id: string;
  entitlement_last_updated: string;
  role_id: string;
  first_name: string;
  last_certified: string;
  last_login: string;
  last_name: string;
  role_name: string;
  status: string;
  user_id: string;
  user_type: string;
  environment_id_arr: string[];
}

export interface ActionReasonType {
  action_reason_id: string;
  action_id: string;
  reason: string;
  created_at: string;
  last_updated: string;
}

export interface AddAccountFormData {
  name: string;
  description: string;
  lob_application_name: string;
}

export interface AddMemberFormData {
  user_id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  partner_id: string;
  account_id: string;
  role_id: string;
  environments: string[];
}

export interface AuditRequest {
  action_reason_id: string;
  user_id?: string;
  performed_by?: string;
  partner_id?: string;
  account_id?: string;
  solution_id?: string;
  environment_id?: string;
  api_id?: string;
  additional_details?: string;
}

export interface APIMetadataType {
  api_id: string;
  apiId?: string; // optional - used for map in AddApiForm
  group_id: string;
  group_name: string;
  name: string;
  short_name: string;
  description: string;
  data_pt_descriptor: string;
  path: string;
  seal: string;
  seal_application_name: string;
  lob_name: string;
  application_owner_sid: string;
  information_owner_sid: string;
  design_authority_sid: string;
  hasDocumentation: boolean;
  hasPlayground: boolean;
  version: string;
  publish_date: string;
  status: string;
}

/*
  Viewbar types
  TODO: Remove specification 
*/

export enum AccountPageViewBarOptions {
  Environments = 'Environments',
  APIs = 'APIs',
}

export enum Permissions {
  ADD_EXTERNAL_COMMENT = 'addExternalComment',
  ADD_EXTERNAL_ATTACHMENT = 'addExternalAttachment',
}

export enum ApiPageViewBarOptions {
  Overview = 'Overview',
  Guides = 'Guides',
  Specification = 'Specification',
}
export enum ApiPageViewBarOptionsNoGuides {
  Overview = 'Overview',
  Specification = 'Specification',
}

export enum ActionType {
  REMOVE_USER = 'remove_user',
  DEACTIVATE_PARTNER = 'deactivate_partner',
  DEACTIVATE_ACCOUNT = 'deactivate_account',
  DEACTIVATE_SOLUTION = 'deactivate_solution',
  DEACTIVATE_ENVIRONMENT = 'deactivate_environment',
  DEACTIVATE_API = 'deactivate_api',

  DELETE_GROUP_MEMBER = 'DELETE_GROUP_MEMBER',
  DELETE_GROUP_RULE = 'DELETE_GROUP_RULE',
}

export interface UserInfoType {
  firstName: string;
  lastName: string;
  emailAddress: string;
  partner: string;
  account: string;
  solution?: string;
  environment: any;
  api: any;
}

export interface SolutionInfoType {
  partner: string;
  account: string;
  solution: string;
}

export interface ICreateUserGroupRequest {
  group_type: string;
  group_name: string;
  description: string;
}

export interface IErrorResponse {
  timestamp: string;
  status: string;
  error: string;
}

export interface DeleteMemberFormData {
  user_id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  account_id: string;
  partner_id: string;
  role_id: string;
  role_name: string;
  environments: string[];
}
