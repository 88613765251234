import { lazy } from 'react';
import { Footer } from '../..';
const AnonymousHeader = lazy(
  () =>
    import(
      '../../global-components/Layouts/AnonymousLayout/AnonymousHeader/AnonymousHeader'
    ),
);
const LoggedOutPage = lazy(() => import('./LoggedOutPage/LoggedOutPage'));
const AccessDeniedErrorPage = lazy(
  () => import('./AccessDeniedErrorPage/AccessDeniedErrorPage'),
);

export { AccessDeniedErrorPage, LoggedOutPage, AnonymousHeader, Footer };
