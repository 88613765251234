import { gql } from '@apollo/client';

export const PartnerRelationshipManagerQuery = gql`
  query {
    dashboard {
      lobApplicationId
      lobName
      applicationName
      ticketTypes
      features {
        featureId
        featureName
        featureStatus
      }
      solutions {
        solutionId
        accountId
        partnerId
        lobApplicationId
        name
        accountName
        partnerName
        shortName
        description
        status
        createdAt
        lastUpdated
        memberRole
        members {
          userId
          firstName
          lastName
          emailAddress
          status
          userType
          lastLogin
          lastUpdated
          createdAt
          roleName
          environments
          dateAdded
        }

        solutionEnvironments {
          solutionEnvironmentId
          environmentId
          name
          status
          createdAt
          lastUpdated
          apis {
            apiId
            name
            status
            publishedAt
            version
          }
        }
      }
    }
  }
`;
