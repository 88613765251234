import {
  UserPersonaEnum,
  chaseAdminQuery,
  partnerAdminQuery,
  ticketQuery,
  chaseSupportQuery,
  superAdminQuery,
  chaseRelationShipManagerQuery,
  PartnerRelationshipManagerQuery,
  partnerDeveloperQuery,
  userProfileInfoQuery,
} from './';

import { useQuery } from '@apollo/client';

function selectQuery(userPersona: UserPersonaEnum) {
  switch (userPersona) {
    case UserPersonaEnum.ChaseAdmin:
      return chaseAdminQuery;
    case UserPersonaEnum.ChaseSuperAdmin:
      return superAdminQuery;
    case UserPersonaEnum.ChaseSupportTeam:
      return chaseSupportQuery;
    case UserPersonaEnum.PartnerAdmin:
      return partnerAdminQuery;
    case UserPersonaEnum.PartnerDeveloper:
      return partnerDeveloperQuery;
    case UserPersonaEnum.PartnerRelationshipManager:
      return PartnerRelationshipManagerQuery;

    case UserPersonaEnum.RelationshipManager:
      return chaseRelationShipManagerQuery;
    default:
      throw new Error('Invalid user persona');
  }
}

const useGetPersonaDash = (userPersona: UserPersonaEnum) => {
  const query = selectQuery(userPersona);

  return useQuery(query, {
    pollInterval: 500000,
  });
};

const useGetTicketDash = () => {
  return useQuery(ticketQuery, {
    pollInterval: 60000,
  });
};

const useGetUserProfileInfo = () => {
  return useQuery(userProfileInfoQuery, {
    pollInterval: 60000,
  });
};

export { useGetPersonaDash, useGetTicketDash, useGetUserProfileInfo };
