const setLoginTime = () => {
  const dateString: string = Date.now().toString();
  localStorage.setItem('loginTime', dateString);
};

const getLoginTime = (): number => {
  return parseInt(localStorage.getItem('loginTime') as string) as number;
};
const parseAndAdd10MinToLoginTime = (oldDate: Date): Date => {
  const newDate = new Date();
  newDate.setTime(oldDate.getTime() + 10 * 60 * 1000);
  return newDate;
};

const getExpiryTime = (): Date => {
  const loginTime = getLoginTime();
  const oldDate = new Date(loginTime);
  return parseAndAdd10MinToLoginTime(oldDate);
};

export { setLoginTime, getExpiryTime };
