import { gql } from '@apollo/client';

export const userProfileInfoQuery = gql`
  query {
    userProfile {
      userId
      firstName
      lastName
      emailAddress
      role {
        roleId
        roleType
        name
        actions {
          actionId
          actionName
          actionType
          permission
          featureId
        }
      }
      actions {
        actionId
        actionName
        actionType
        permission
        featureId
      }
    }
  }
`;
