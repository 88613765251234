import React from 'react';
// We only import ReactDOM for @axe-core/react for development mode
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as Element);

if (process.env.NODE_ENV !== 'production') {
  // Warning! @axe-core/react may not support all features of React 18
  // See https://github.com/dequelabs/axe-core-npm/issues/500
  import('@axe-core/react').then((axe) => {
    // Results will show in the DevTools console
    axe.default(React, ReactDOM, 1000);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  });
} else {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
