import { Dispatch, SetStateAction, createContext } from 'react';
import {
  UserProfileInterface,
  ILobApplication,
  IPartner,
  IAccount,
  ISolution,
  IEnvironment,
  ISolutionEnvironment,
  IMember,
  ITicket,
  IContact,
  IGroup,
  IApis,
  IRole,
  IFeature,
  UserAccessInterface,
} from './';

export interface personaMapsInterface {
  lobApplicationMap: Map<string, ILobApplication>;
  partnerMap: Map<string, IPartner>;
  accountMap: Map<string, IAccount>;
  solutionMap: Map<string, ISolution>;
  environmentMap: Map<string, IEnvironment>;
  solEnvMap: Map<string, ISolutionEnvironment>;
  memberMap: Map<string, IMember>;
  apiMap: Map<string, IApis>;
  roleMap: Map<string, IRole>;
  groupMap: Map<string, IGroup>;
  memberRoleMap: Map<string, Set<string>>;
  featureMap: Map<string, IFeature>;
}

export interface ticketMapsInterface {
  ticketMap: Map<string, ITicket>;
  contactMap: Map<string, IContact>;
}

export default interface GlobalStoreProviderInterface {
  userProfile: UserProfileInterface;
  userAccess: UserAccessInterface;
  setUserAccess: Dispatch<SetStateAction<UserAccessInterface>>;
  personaMaps: personaMapsInterface;
  setPersonaMaps: Dispatch<SetStateAction<personaMapsInterface>>;
  ticketMaps: ticketMapsInterface;
  setTicketMaps: Dispatch<SetStateAction<ticketMapsInterface>>;
  isMobile: boolean;
  isLeftSideNavOpen: boolean;
  setIsLeftSideNavOpen: Dispatch<SetStateAction<boolean>>;
  isMobileWithLSN: boolean;
}

export const GlobalStoreContext = createContext<GlobalStoreProviderInterface>(
  {} as GlobalStoreProviderInterface,
);
