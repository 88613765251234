import { gql } from '@apollo/client';

export const superAdminQuery = gql`
  query {
    dashboard {
      lobApplicationId
      lobName
      applicationName
      ticketTypes
      roles {
        roleId
        name
        description
        roleType
      }
      environments {
        environmentId
        name
        status
        createdAt
        lastUpdated
      }
      groups {
        groupId
        groupName
        description
        createdAt
        lastUpdated
        groupType
        createdBy {
          userId
          firstName
          lastName
        }
        groupRules {
          ruleId
          groupId
          lobApplicationId
          partnerId
          accountId
          solutionId
          solutionEnvironmentId
          customersImpacted
          ticketSeverity
          ticketType
          isDefaultRule
          createdAt
          lastUpdated
          createdBy {
            userId
            firstName
            lastName
          }
        }
        members {
          userId
          firstName
          lastName
          roleName
          createdAt
          createdBy {
            userId
            firstName
            lastName
          }
        }
      }
      partners {
        partnerId
        name
        shortName
        description
        createdAt
        lastUpdated
        status
        accounts {
          accountId
          partnerId
          lobApplicationId
          description
          name
          partnerName
          shortName
          status
          createdAt
          lastUpdated
          solutions {
            solutionId
            accountId
            name
            shortName
            description
            status
            createdAt
            lastUpdated
            memberRole
            members {
              userId
              firstName
              lastName
              emailAddress
              status
              userType
              lastLogin
              lastUpdated
              createdAt
              roleName
              environments
              dateAdded
            }
            solutionEnvironments {
              solutionEnvironmentId
              environmentId
              name
              status
              createdAt
              lastUpdated
              apis {
                apiId
                name
                status
                publishedAt
                version
              }
            }
          }
        }
      }
    }
  }
`;
